"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.THEME = void 0;
/**
 * This is the base theme for the design system, used in patient-facing apps.
 * That also means that other themes (like operator) must match the shape of
 * this object exactly - something to be aware of when adding new theme-able values.
 */
const THEME = {
  accordion: {
    component: {
      top: "border-t border-gray-e7e9ef",
      bottom: "border-b border-gray-e7e9ef",
      "top-bottom": "border-t border-b border-gray-e7e9ef"
    },
    card: {
      base: "rounded-16 shadow-0-0-2-4-black-1/4",
      header: {
        variant: "body",
        m: "",
        rounded: "rounded-16",
        gapX: "px-24",
        gapY: "py-24"
      },
      subtitle: {
        variant: "body",
        base: "text-left text-gray-65657b"
      },
      iconContainer: "flex items-center gap-x-16",
      panel: {
        default: "p-24",
        icon: "p-24",
        leftChevron: "p-24",
        leftChevronIcon: "p-24"
      }
    },
    header: {
      base: "flex items-center w-full justify-between text-left text-gray-171731",
      inactive: "hover:bg-gray-e7e9ef cursor-pointer",
      subtitle: "text-left text-gray-65657b",
      open: "bg-gray-f4f5f7",
      disabled: "opacity-50 cursor-not-allowed",
      iconColor: "#65657b",
      baseReversed: "flex flex-row-reverse items-center w-full justify-end text-gray-171731 gap-x-8",
      chevron: "",
      title: "",
      titleVariant: "body"
    },
    panel: {
      textVariant: "body"
    }
  },
  alert: {
    base: "px-24 py-16 rounded-8 text-1 leading-1_5 border",
    box: "flex gap-x-16",
    headline: "",
    headlineVariant: "body-md",
    headlineWrapper: "flex-1",
    bodyVariant: "body",
    caption: "mt-16 pt-8 border-t border-gray-a9acc4 text-gray-65657b",
    closeBtn: "self-start text-gray-65657b hover:text-gray-171731",
    info: {
      bgColor: "bg-gray-f4f5f7",
      iconColor: "text-gray-65657b",
      border: "border-gray-a9acc4"
    },
    error: {
      bgColor: "bg-red-fff6f6",
      iconColor: "text-red-c34648",
      border: "border-red-c34648"
    },
    warning: {
      bgColor: "bg-yellow-fcf5e6",
      iconColor: "text-yellow-bd8236",
      border: "border-yellow-bd8236"
    },
    success: {
      bgColor: "bg-green-e2fcf9",
      iconColor: "text-green-227c6c",
      border: "border-green-227c6c"
    }
  },
  badge: {
    align: "inline-flex items-center",
    sm: {
      padding: "px-8 py-2",
      rounded: "rounded-6",
      text: "",
      textVariant: "tiny"
    },
    lg: {
      padding: "px-8 py-4",
      rounded: "rounded-8",
      text: "",
      textVariant: "small"
    },
    green: {
      bgColor: "bg-green-e2fcf9",
      border: "border border-green-227c6c",
      icon: "flex-shrink-0 text-green-227c6c mr-8"
    },
    blue: {
      bgColor: "bg-primary-e4edfe",
      border: "border border-primary-3667e9",
      icon: "flex-shrink-0 text-primary-3667e9 mr-8"
    },
    gray: {
      bgColor: "bg-gray-f4f5f7",
      border: "border border-gray-6d7088",
      icon: "flex-shrink-0 text-gray-6d7088 mr-8"
    },
    yellow: {
      bgColor: "bg-yellow-fcf5e6",
      border: "border border-yellow-bd8236",
      icon: "flex-shrink-0 text-yellow-bd8236 mr-8"
    },
    red: {
      bgColor: "bg-red-fff6f6",
      border: "border border-red-c34648",
      icon: "flex-shrink-0 text-red-c34648 mr-8"
    }
  },
  breadcrumbs: {
    component: {
      p: "py-8 px-24",
      border: "border-gray-e7e9ef border-b-1"
    },
    crumb: {
      base: "text-gray-65657b",
      interactive: "cursor-pointer hover:underline focus:rounded-8 focus:outline-3667e9"
    }
  },
  button: {
    base: "border-2 font-500 tracking-0_2",
    centerAligned: "inline-flex items-center justify-center",
    size: {
      sm: "px-22 py-6 rounded-8 leading-1_5 text-0_875",
      md: "px-38 py-10 rounded-12 leading-1_5 text-1"
    },
    disabled: "opacity-50 cursor-not-allowed",
    primary: {
      default: {
        border: "border-transparent",
        bg: "bg-primary-3667e9",
        text: "text-white",
        hover: "hover:bg-primary-365fc1",
        focus: "focus:outline-3667e9"
      },
      danger: {
        border: "border-transparent",
        bg: "bg-red-c34648",
        text: "text-white",
        hover: "hover:bg-red-a6191c",
        focus: "focus:outline-3667e9"
      },
      dark: {
        border: "border-transparent",
        bg: "bg-primary-3667e9",
        text: "text-white",
        hover: "hover:bg-primary-365fc1",
        focus: "focus:outline-3667e9"
      }
    },
    secondary: {
      default: {
        border: "border-gray-e7e9ef",
        bg: "",
        text: "text-primary-3667e9",
        hover: "hover:outline-none hover:bg-gray-f3f4f7",
        focus: "focus:border-primary-3667e9 focus:shadow-0-0-0-4-cfe5ff focus:outline-none"
      },
      danger: {
        border: "border-red-c34648",
        bg: "",
        text: "text-red-c34648",
        hover: "hover:bg-gray-f3f4f7",
        focus: "focus:border-primary-3667e9 focus:shadow-0-0-0-4-cfe5ff focus:outline-none"
      },
      dark: {
        border: "border-gray-e7e9ef",
        bg: "",
        text: "text-primary-3667e9",
        hover: "hover:outline-none hover:bg-gray-f3f4f7",
        focus: "focus:border-primary-3667e9 focus:shadow-0-0-0-4-cfe5ff focus:outline-none"
      }
    },
    tertiary: {
      default: {
        border: "border-transparent",
        bg: "bg-transparent",
        text: "text-primary-3667e9",
        hover: "hover:outline-none hover:bg-gray-f3f4f7",
        focus: "focus:border-primary-3667e9 focus:shadow-0-0-0-4-cfe5ff focus:outline-none"
      },
      danger: {
        border: "border-transparent",
        bg: "bg-transparent",
        text: "text-red-c34648",
        hover: "hover:outline-none hover:bg-gray-f3f4f7",
        focus: "focus:border-primary-3667e9 focus:shadow-0-0-0-4-cfe5ff focus:outline-none"
      },
      dark: {
        border: "border-transparent",
        bg: "bg-transparent",
        text: "text-gray-65657b",
        hover: "hover:outline-none hover:bg-gray-f3f4f7",
        focus: "focus:border-primary-3667e9 focus:shadow-0-0-0-4-cfe5ff focus:outline-none"
      }
    },
    icon: {
      disabled: "opacity-50 cursor-not-allowed",
      hover: "hover:bg-gray-f3f4f7",
      focus: "focus:outline-3667e9",
      padding: {
        sm: "p-4",
        lg: "p-4"
      },
      rounded: "rounded-4"
    }
  },
  checkbox: {
    component: {
      base: "checkbox",
      alignTop: "items-start",
      alignCenter: "items-center",
      spacing: "pl-16",
      container: "flex",
      disabled: "opacity-50",
      cursorNotAllowed: "cursor-not-allowed"
    },
    card: {
      padding: "px-16 py-12",
      border: "border",
      rounded: "rounded-8",
      selected: "border-primary-3667e9 bg-primary-f2f8ff",
      unselected: "border-gray-6d7088",
      alignTop: "items-start",
      alignCenter: "items-center",
      container: "flex",
      disabled: "opacity-50 cursor-not-allowed",
      hover: "cursor-pointer"
    },
    group: {
      horizontal: "flex-row gap-x-32",
      vertical: "flex-col gap-y-16"
    }
  },
  datePicker: {
    component: {
      inputPadding: 64,
      minWidth: 290,
      maxWidth: 400
    },
    popup: {
      inner: "border border-gray-e7e9ef bg-white rounded-8 shadow-0-8-10-1-6968681f p-16",
      content: "mt-8",
      header: "flex justify-between pt-16 pb-8 border-b border-gray-a9acc4",
      headerCell: "w-36 text-center text-gray-65657b",
      row: "flex justify-between mt-8",
      color: "text-gray-171731",
      cell: "w-36 h-36 rounded-full border-2 border-transparent text-center leading-2",
      hover: "hover:border-primary-3667e9",
      selected: "bg-primary-3667e9 text-white hover:bg-primary-365fc1",
      disabled: "text-gray-171731 opacity-50"
    },
    header: {
      root: "flex justify-between",
      button: "bg-white hover:bg-gray-f3f4f7",
      title: "text-center"
    }
  },
  input: {
    component: {
      base: "flex-1 text-1 appearance-none placeholder-gray-6d7088 focus:outline-none py-12",
      disabled: "opacity-50 cursor-not-allowed bg-gray-f4f5f7",
      readOnly: "opacity-50 cursor-default bg-gray-f4f5f7",
      label: {
        variant: "small-md",
        spacing: "text-gray-65657b block mb-8",
        disabled: "opacity-50"
      },
      leftAddon: {
        default: "flex items-center pl-16 flex-grow-0 flex-shrink-0 rounded-8",
        paddingRight: "pr-8"
      },
      rightAddon: {
        default: "flex items-center pl-8 pr-16 flex-grow-0 flex-shrink-0 rounded-8",
        paddingLeft: "pl-8",
        error: "text-red-c34648",
        success: "text-green-227c6c"
      },
      inputWrapper: {
        border: "border rounded-8 focus-within:border-primary-3667e9 bg-white",
        default: "border-gray-6d7088",
        error: "border-red-c34648",
        success: "border-green-227c6c",
        disabled: ""
      }
    },
    helperText: {
      base: "w-full font-400 text-0_875 leading-1_5 mt-8",
      error: "text-red-c34648",
      helper: "text-gray-65657b"
    },
    textarea: {
      base: "h-48 w-full px-16 py-6 appearance-none placeholder-gray-6d7088 focus:outline-none focus:border-primary-3667e9",
      disabled: "opacity-50 cursor-not-allowed",
      border: "border-gray-6d7088 border rounded-8"
    }
  },
  layout: {
    divider: {
      default: "border-l border-gray-e7e9ef",
      horizontal: "border-t border-gray-e7e9ef",
      height: "h-auto"
    },
    flex: {
      base: "flex",
      inline: "inline-flex"
    },
    panel: {
      shadow: "shadow-0-2-4-0",
      rounded: "rounded-12",
      padding: "px-24 py-32 s768:p-32"
    }
  },
  link: {
    base: "cursor-pointer hover:underline focus:rounded-8 focus:outline-3667e9",
    itemsCenter: "inline-flex items-center",
    default: "text-primary-3667e9",
    danger: "text-red-c34648",
    dark: "text-gray-65657b",
    defaultSize: "md",
    variants: {
      tiny: "tiny-md",
      sm: "small-md",
      md: "body-md"
    }
  },
  menu: {
    button: {
      base: "flex items-center"
    },
    item: {
      base: {
        position: "block",
        text: "text-left text-primary-3667e9 hover:underline",
        font: "font-500",
        p: "px-24 py-8",
        w: "100%",
        hover: "hover:cursor-pointer"
      },
      active: "rounded-8 bg-gray-f4f5f7 outline-3667e9 hover:outline-none",
      disabled: "text-gray-171731 cursor-not-allowed opacity-50 hover:cursor-not-allowed hover:no-underline"
    },
    items: {
      container: {
        p: "py-8",
        bg: "bg-white",
        shadow: "shadow-0-4-5-0-fff",
        rounded: "rounded-8",
        focus: "focus-within:outline-none",
        z: "z-10"
      },
      transition: {
        leave: "transition ease-in duration-100",
        leaveFrom: "opacity-100",
        leaveTo: "opacity-0"
      }
    }
  },
  modal: {
    component: {
      root: "relative z-40",
      base: "relative flex flex-col overflow-hidden rounded-t-16 bg-white px-24 py-24 shadow-xl transition-all max-h-9/10 s768:max-h-4/5 w-full s576:rounded-b-16 s768:px-32",
      size: {
        sm: "s576:max-w-lg",
        md: "s768:max-w-2xl",
        lg: "s992:max-w-4xl"
      },
      backdrop: "fixed inset-0 bg-gray-171731",
      backdropAnimation: {
        enter: "ease-out duration-300",
        enterFrom: "opacity-0",
        enterTo: "opacity-50",
        leave: "ease-in duration-200",
        leaveFrom: "opacity-50",
        leaveTo: "opacity-0"
      },
      panelAnimation: {
        enter: "ease-out duration-300",
        enterFrom: "opacity-0 translate-y-4",
        enterTo: "opacity-100 translate-y-0",
        leave: "ease-in duration-200",
        leaveFrom: "opacity-100 translate-y-0",
        leaveTo: "opacity-0 translate-y-4"
      },
      center: "fixed inset-0 flex min-h-full justify-center items-end s576:items-center",
      closeBtn: {
        position: "absolute right-0 top-0 pr-24 pt-24",
        base: "cursor-pointer text-gray-65657b hover:text-gray-171731 focus:outline-none focus-visible:outline-3667e9",
        size: "h-24 w-24"
      }
    },
    body: {
      base: "overflow-y-auto mt-24 mb-16"
    },
    footer: {
      base: "s576:flex s576:flex-row-reverse"
    },
    header: {
      base: "text-gray-171731 pr-24",
      text: "",
      variant: "header"
    }
  },
  progressBar: {
    base: "flex w-full rounded-full overflow-hidden bg-gray-e7e9ef",
    progress: "flex flex-col justify-center overflow-hidden text-xs text-white text-center rounded-r-full",
    defaultColor: "bg-primary-3667e9",
    sm: "h-8",
    md: "h-16",
    lg: "h-24"
  },
  radio: {
    component: {
      base: "radio",
      alignTop: "items-start",
      alignCenter: "items-center",
      spacing: "pl-16",
      gapTop: "mt-2",
      container: "flex",
      disabled: "opacity-50",
      cursorNotAllowed: "cursor-not-allowed"
    },
    card: {
      padding: "px-16 py-12",
      border: "border",
      rounded: "rounded-8",
      selected: "border-primary-3667e9 bg-primary-f2f8ff",
      unselected: "border-gray-6d7088",
      alignTop: "items-start",
      alignCenter: "items-center",
      container: "flex",
      disabled: "opacity-50 cursor-not-allowed",
      hover: "cursor-pointer"
    },
    group: {
      horizontal: "flex-row gap-x-32",
      vertical: "flex-col gap-y-16"
    }
  },
  select: {
    menu: {
      component: {
        base: "py-8 w-full rounded-8 bg-white shadow-0-4-5-0-fff absolute z-10 focus:outline-none overflow-y-auto",
        leave: "transition ease-in duration-100",
        leaveFrom: "opacity-100",
        leaveTo: "opacity-0"
      },
      button: {
        base: "w-full flex items-center justify-between bg-white px-16 py-12 border rounded-8",
        focus: "focus:outline-3667e9",
        default: "border-gray-6d7088",
        active: "border-primary-3667e9",
        error: "border-red-c34648",
        disabled: "opacity-50 cursor-not-allowed bg-gray-f4f5f7"
      },
      item: {
        base: "flex items-center justify-between px-24 py-8",
        disabled: "opacity-50 cursor-not-allowed",
        active: "bg-gray-f4f5f7",
        selected: "text-primary-3667e9"
      }
    },
    custom: {
      errorText: "w-full text-red-c34648 mt-8",
      helperText: "w-full text-gray-65657b mt-8",
      errorHelperVariant: "small",
      label: {
        spacing: "text-gray-65657b block mb-8",
        disabled: "opacity-50",
        font: "font-500 text-0_875 leading-1_5 tracking-0_2"
      }
    },
    native: {
      base: "w-full h-48 px-16 border rounded-8 focus:outline-3667e9",
      disabled: "opacity-50 cursor-not-allowed bg-gray-f4f5f7",
      error: "error",
      errorText: "w-full text-red-c34648 mt-8",
      helperText: "w-full text-gray-65657b mt-8",
      errorHelperVariant: "small",
      label: {
        spacing: "text-gray-65657b block mb-8",
        disabled: "opacity-50",
        variant: "small-md"
      }
    }
  },
  tab: {
    component: {
      default: "flex justify-center items-center p-12 -mb-3 border-b-3 focus:text-primary-3667e9 focus:outline-none hover:text-primary-3667e9",
      disabled: "hover:text-gray-65657b opacity-50",
      enabled: "",
      selected: "text-primary-3667e9 border-primary-3667e9",
      unselected: "border-gray-e7e9ef",
      fill: "flex-1",
      icon: "mr-4",
      text: {
        variant: "body-md",
        selectedVariant: "body-md"
      }
    },
    list: {
      base: "flex border-b-3 border-gray-e7e9ef text-gray-65657b"
    }
  },
  text: {
    display: "font-serif font-700 text-1_75 leading-1_25 tracking-0_8 s768:text-2 s768:tracking-1",
    title: "font-serif font-700 text-1_5 leading-1_25 tracking-0_8",
    header: "font-400 text-1_25 leading-1_2 tracking-0_2",
    "header-md": "font-500 text-1_25 leading-1_2 tracking-0_2",
    subheader: "font-500 text-1 leading-1_5 tracking-0_2",
    body: "font-400 text-1 leading-1_5 tracking-0_2",
    "body-md": "font-500 text-1 leading-1_5 tracking-0_2",
    small: "font-400 text-0_875 leading-1_5 tracking-0_2",
    "small-md": "font-500 text-0_875 leading-1_5 tracking-0_2",
    tiny: "font-400 text-0_75 leading-1_5 tracking-0_2",
    "tiny-md": "font-500 text-0_75 leading-1_5 tracking-0_2"
  },
  toast: {
    base: "px-24 py-16 rounded-8 border shadow-0-2-4-0",
    container: "fixed z-40 justify-center top-8 left-8 right-8",
    title: "",
    textContainer: "flex-1",
    topLeft: "s576:top-16 s576:left-16 s576:right-auto",
    topRight: "s576:top-16 s576:right-16 s576:left-auto",
    topCenter: "s576:left-1/2 s576:right-auto s576:-translate-x-1/2",
    box: "flex gap-x-8",
    info: {
      bgColor: "bg-gray-f4f5f7",
      iconColor: "text-gray-65657b",
      border: "border-gray-a9acc4"
    },
    error: {
      bgColor: "bg-red-fff6f6",
      iconColor: "text-red-c34648",
      border: "border-red-c34648"
    },
    warning: {
      bgColor: "bg-yellow-fcf5e6",
      iconColor: "text-yellow-bd8236",
      border: "border-yellow-bd8236"
    },
    success: {
      bgColor: "bg-green-e2fcf9",
      iconColor: "text-green-227c6c",
      border: "border-green-227c6c"
    },
    close: "text-gray-65657b cursor-pointer mt-4",
    closeSize: 16,
    animation: {
      enter: "ease-out duration-300",
      enterFrom: "opacity-0",
      enterTo: "opacity-100",
      leave: "ease-in duration-200",
      leaveFrom: "opacity-100",
      leaveTo: "opacity-0"
    }
  },
  toggle: {
    base: "relative rounded-full box-content inline-flex border-2 border-transparent transition-colors duration-200 ease-in-out items-center",
    on: "bg-primary-3667e9",
    off: "bg-gray-65657b",
    focus: "focus:outline-3667e9",
    disabled: "cursor-not-allowed opacity-50",
    icon: "inline-block rounded-full bg-white transform transition duration-200 ease-in-out",
    sm: {
      base: "h-20 w-44",
      icon: "p-2",
      check: "translate-x-24"
    },
    md: {
      base: "h-32 w-60",
      icon: "p-8",
      check: "translate-x-28"
    }
  },
  tooltip: {
    base: "bg-white rounded-12 shadow-0-8-10-1-6968681f",
    gapX: "px-16",
    gapY: "py-16",
    maxWidth: "270px",
    headlineVariant: "body-md",
    contentVariant: "body"
  }
};
exports.THEME = THEME;