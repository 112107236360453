import React, { useEffect, useState } from "react";
import { Modal } from "design_system/Modal";
import { Leaf } from "design_system/Icon";
import { Button } from "design_system/Button";
import { Text } from "design_system/Text";
import { SuccessToast } from "./SuccessToast";
import { useSuppressPaperlessNudgeForCustomer } from "../../hooks/mutations/useSuppressPaperlessNudgeForCustomer";
import { useUpdateCommunicationConsent } from "../../hooks/mutations/useUpdateCommunicationConsent";
import { useServerData } from "../../hooks/useServerData";
import { gql, useQuery } from "@apollo/client";

/**
 * This hook checks the URL for either the user public id, or a link hash, and then
 * uses that identifier to query GraphQL for the guest information, giving us the
 * user id and notification center url
 */
const useGuestInformation = () => {
    const queryParams = new URL(window.location.href).searchParams;
    const userId = queryParams.get("user");
    const linkHash = queryParams.get("link");

    const GUEST_QUERY = gql`
        query GuestQuery($userPublicId: String, $linkHash: String) {
            customer(userPublicId: $userPublicId, linkHash: $linkHash) {
                publicId
                patientAppMenuContext {
                    notificationCenterUrl
                }
            }
        }
    `;

    const { data, loading, error } = useQuery(GUEST_QUERY, {
        variables: {
            linkHash: linkHash || undefined,
            userPublicId: userId || undefined,
        },
        skip: !(userId || linkHash),
    });

    return { data, loading, error };
};

/**
 * A modal that shows in the guest experience and prompts the user to go paperless.
 * Whether this displays or not is controlled by a flag from the backend.
 * @param userPublicId
 * @constructor
 */
export const GuestGoPaperlessModal: React.FC = () => {
    const modalServerData = useServerData("guest_go_paperless_modal");
    const globalServerData = useServerData("global");
    const [open, setOpen] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const { data: guestData } = useGuestInformation();

    const [suppressPaperlessNudgeForCustomer] = useSuppressPaperlessNudgeForCustomer({
        variables: {
            userPublicId: guestData?.customer?.publicId,
        },
    });
    const [updateCommunicationConsent, updateCommunicationConsentResult] =
        useUpdateCommunicationConsent();

    const handleGoPaperless = () => {
        if (guestData?.customer?.publicId) {
            updateCommunicationConsent({
                variables: {
                    userPublicId: guestData?.customer?.publicId,
                    email: true,
                    paperMail: false,
                    source: "guest_go_paperless_nudge",
                },
            });
        }
    };

    useEffect(() => {
        const shouldShowModal =
            modalServerData?.show_guest_paperless_modal &&
            globalServerData?.logged_in === false &&
            sessionStorage.getItem("guest_go_paperless_modal_seen") !== "true";
        if (guestData?.customer?.publicId && shouldShowModal) {
            setOpen(true);
            sessionStorage.setItem("guest_go_paperless_modal_seen", "true");
        }
    }, [guestData, modalServerData, globalServerData]);

    useEffect(() => {
        if (updateCommunicationConsentResult.data?.updateCommunicationConsent?.success && open) {
            setOpen(false);
            setOpenToast(true);
        }
    }, [updateCommunicationConsentResult, open]);

    const onClose = async () => {
        await suppressPaperlessNudgeForCustomer();
        setOpen(false);
    };
    return (
        <>
            <Modal onClose={onClose} open={open} size="sm">
                <Modal.Body>
                    <div className="flex justify-center">
                        <Leaf size={40} spotColor="#C9DBFE" strokeWidth="0.5" aria-hidden="true" />
                    </div>
                    <Text variant="display" className="text-center mb-16 text-primary-13126c">
                        Simplify. Go paperless.
                    </Text>
                    <Text className="text-center mb-16">
                        Simplify your life with instant bill notifications and securely manage your
                        medical bills online.
                    </Text>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <Button onClick={handleGoPaperless} fullWidth className="mb-8">
                            Go paperless in one click
                        </Button>
                        <Button variant="tertiary" onClick={onClose} fullWidth>
                            I’ll do this later
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <SuccessToast
                open={openToast}
                setOpen={setOpenToast}
                notificationCenterUrl={
                    guestData?.customer?.patientAppMenuContext?.notificationCenterUrl || ""
                }
            />
        </>
    );
};
