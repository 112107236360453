import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import type {
    suppressPaperlessNudgeForCustomerMutation,
    suppressPaperlessNudgeForCustomerMutationVariables,
} from "src/hooks/mutations/__generated__/useSuppressPaperlessNudgeForCustomer";

export const SUPPRESS_PAPERLESS_NUDGE_FOR_CUSTOMER = gql`
    mutation suppressPaperlessNudgeForCustomer($userPublicId: String) {
        suppressPaperlessNudgeForCustomer(userPublicId: $userPublicId) {
            success
            errors
        }
    }
`;

type SuppressPaperlessNudgeForCustomerParams = {
    variables: suppressPaperlessNudgeForCustomerMutationVariables;
};

export function useSuppressPaperlessNudgeForCustomer({
    variables,
}: SuppressPaperlessNudgeForCustomerParams) {
    return useMutation<
        suppressPaperlessNudgeForCustomerMutation,
        suppressPaperlessNudgeForCustomerMutationVariables
    >(SUPPRESS_PAPERLESS_NUDGE_FOR_CUSTOMER, {
        variables,
    });
}
